import React, { useEffect } from 'react';
import Resizer from "react-image-file-resizer";
import { MediaUpload } from '../../../../Constant/api';

export default function AudioUpload(props) {
  const {
    ImageUrl,
    setImageUrl,change_type,
    index, QuestionsData, setQuestionsData 
  } = props;
  const fileRefSingle = React.createRef();
  const [Loading, setLoading] = React.useState(false)

  const MediaUploadSingleCall = (file, i) => {
    if (!file) return;
    var fileInput = "";
    if (file) {
      fileInput = file.type;
    }
    console.log("2", file)
    // setMediaUploadType(file.type.split("/")[0])
    MediaUpload(file)
      .then(res => res.json())
      .then(data => {
        console.log(data)
        setImageUrl(data.source_url)
        var question = QuestionsData;
        var questionkey = Object.keys(QuestionsData)[index]
        var questionvalue = Object.values(QuestionsData)[index]
        console.log(questionkey, questionvalue)
        question = {
          ...question,
          [questionkey]: {
            ...questionvalue,
            [change_type]: `${data.source_url}`,
          }
        }
        setQuestionsData(question)
        setTimeout(() => {
          setLoading(false)
        }, 1000);
      })
      .catch(err => { console.log("error", err.message); })
      .finally(() => { })
  }

  return (
    <div>
      {Loading &&
        <div className="spinner-border"></div>
      }
      <div className="col-lg-6 col-md-6">
        <div className="row">

          {ImageUrl !== "" ?
            <div className="col-3 my-2">
              <div className=" ">
                <audio controls>
                  {/* <source src={`${ImageUrl}`} type="audio/ogg" /> */}
                  <source src={`${ImageUrl}`} type="audio/mpeg" />
                  <source src={`${ImageUrl}`} type="audio/mp3" />
                  <source src={`${ImageUrl}`} type="audio/wav" />
                  <source src={`${ImageUrl}`} type="audio/ogg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
              <button onClick={() => {
                setImageUrl("")
              }}
                className="bg-transparent border-0 p-1 "
              ><u>Clear</u></button>
            </div>
            :
            <div className="col-3">
              <button type="button"
                onClick={() => {
                  fileRefSingle.current.click()
                }}
                className="bg-transparent border-0 p-1 "
              >
                {/* <div className="border border-dark m-1 p-4 font-size-12"> */}
                  {/* <i className="fa fa-headphone font-size-18"></i> */}
                  <img src="https://api.gidohae.com/wp-content/uploads/2023/05/headpone.png"
                    style={{ width: "20px", height: "20px" }}
                  />
                  <div>Add</div>
                {/* </div> */}
              </button>
            </div>
          }
        </div>
      </div>
      <div style={{ display: 'none' }}>
        <input type="file" accept="audio/*" name="image" id="file" ref={fileRefSingle}
          onChange={(event) => {
            if (event.target.files.length !== 0) {
              setLoading(true)
              MediaUploadSingleCall(event.target.files[0])
            }
          }}
        />
      </div>

    </div>
  )
}