import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import Database from '../../../../Constant/database.json'
import ImageUpload from "../../../ImageUpload";
import ExamQuestionModal from "./ExamQuestionModal";
import { CustomButtonUnSelect } from "../../../CustomButtomUnselect";
import AudioUpload1 from "../../../AudioUpload";
import AudioUpload from "./AudioUpload";

const QuestionBlock = [
  "question_number",
  "question",
  "question_description",
  "question_description_type",
  "question_media",
  "question_media_type",
  "option_1",
  "option_2",
  "option_3",
  "option_4",
  "answer_description",
  "correct_answer",
  "answer_media_type",
]

export default function FormComponent(props) {
  const { FetchData, db_slug, webSlug } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [Time, setTime] = React.useState(FetchData.meta.exam_time)
  const [Details, setDetails] = React.useState(FetchData.meta.exam_details)
  const [Available, setAvaliable] = React.useState(FetchData.meta.available_unavailable)
  const [FeaturedImage, setFeaturedImage] = React.useState(FetchData.meta.featured_image)
  const [FeaturedAudio, setFeaturedAudio] = React.useState(FetchData.meta.featured_audio)
  const [ExamType, setExamType] = React.useState(FetchData.meta.exam_type)
  const [ExamSubType, setExamSubType] = React.useState(FetchData.meta.exam_sub_type)
  const [CopyTo, setCopyTo] = React.useState("")
  const [AudioSingle, setAudioSingle] = React.useState(FetchData.meta.media_non_repeat)
  const QuestionDataValue = JSON.parse(FetchData.meta.exam_question_block_text)
  const [NumberofQuestions, setNumberofQuestions] = React.useState(Object.values(QuestionDataValue).length)
  const [NumberofQuestionSet, setNumberofQuestionSet] = React.useState(Object.values(QuestionDataValue).length == 0 ? true : false)

  const [QuestionsBlock, setQuestionsBlock] = React.useState(true);
  const [QuestionsData, setQuestionsData] = React.useState(JSON.parse(FetchData.meta.exam_question_block_text));
  const [QuestionsEditMode, setQuestionsEditMode] = React.useState(false);
  const [QuestionsEditData, setQuestionsEditData] = React.useState({});
  const [QuestionsEditKey, setQuestionsEditKey] = React.useState();

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const EditInfo = (POSTID, slug, passSlug) => {
    setReload(true)
    console.log(QuestionsData)
    var data = {
      "title": Title,
      "status": "publish",
      "meta": {
        "exam_question_block_text": JSON.stringify(QuestionsData),
        "exam_time": Time,
        "exam_details": Details,
        "available_unavailable": Available,
        "featured_image": FeaturedImage,
        "media_non_repeat": AudioSingle,
        "exam_type": ExamType,
        "exam_sub_type": ExamSubType
      }
    }
    if (POSTID === "")
      AdsPOSTApi(data, slug)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Exam Added Successfully")
          console.log(res.data)
          history.push(`${passSlug}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slug, POSTID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }


  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Exam Information</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Exam Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>

              <tr>
                <td className="col-3">Exam Time (in minutes)</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Time} placeholder="Exam Time"
                    onChange={(event) => { setTime(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Details</td>
                <td className="col-6">
                  <textarea rows={5} className="input-common"
                    value={Details} placeholder="Exam Details"
                    onChange={(event) => { setDetails(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Featured Image</td>
                <td className="col-6">
                  <ImageUpload
                    ImageUrl={FeaturedImage}
                    setImageUrl={setFeaturedImage}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Featured Audio</td>
                <td className="col-6">
                  <AudioUpload1
                    ImageUrl={FeaturedAudio}
                    setImageUrl={setFeaturedAudio}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Audio Single</td>
                <td className="col-6">
                  <div style={{ display: "inline" }}>
                    <div style={{ display: "inline-block" }}>
                      <CustomButtonUnSelect setData={setAudioSingle} Data={AudioSingle} DataValue={"Single"} />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Name</td>
                <td className="col-6">
                  <CustomButton setData={setAvaliable} Data={Available} DataValue={"Available"} />
                  <CustomButton setData={setAvaliable} Data={Available} DataValue={"Unavailable"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Type</td>
                <td className="col-6">
                  <div style={{ display: "inline" }}>
                    {/* {Database.exam_type.map((item, index) => ( */}
                    {/* <div style={{ display: "inline-block" }} key={index}>
                        <CustomButton setData={} Data={ExamType} DataValue={item} />
                      </div> */}
                    {ExamType}
                    {/* ))} */}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Sub Type</td>
                <td className="col-6">
                  <div>
                    <CustomButtonUnSelect setData={setExamSubType} Data={ExamSubType} DataValue={"Free"} />
                    {ExamType == "CBT" &&
                      <div style={{ display: "inline" }}>
                        <>
                          {Database.cbt_type.map((item, index) => (
                            <div style={{ display: "inline-block" }} key={index}>
                              <CustomButtonUnSelect setData={setExamSubType} Data={ExamSubType} DataValue={item} />
                            </div>
                          ))}
                        </>
                      </div>
                    }
                  </div>
                  {/* {Database.exam_type.map((item, index) => ( */}
                  {/* <div style={{ display: "inline-block" }} key={index}>
                        <CustomButton setData={} Data={ExamType} DataValue={item} />
                      </div> */}

                  {/* ))} */}
                </td>
              </tr>

            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo(ID, db_slug, webSlug)
                }}
              >
                Update Exam
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-1 col-1"></div>
        <div className="col-sm-10 col-10 form-view mb-3">
          <h4><center>Questions</center></h4>
          {NumberofQuestionSet ?
            <div>
              <table>
                <tr>
                  <td className="col-3">Number of Questions</td>
                  <td className="col-6">
                    <input className="input-common"
                      value={NumberofQuestions} placeholder="Number of questions"
                      onChange={(event) => { setNumberofQuestions(event.target.value) }}
                    />
                  </td>
                </tr>
              </table>
              <div>
                <div className="row center m-2">
                  <div className=" col-3" style={{ padding: "0 10px" }}>
                    <div className="sign-in-button-4"
                      onClick={() => {
                        // setQuestionsData
                        var question = {};
                        for (let i = 0; i < NumberofQuestions; i++) {
                          question = {
                            ...question,
                            [`item-${i}`]: {
                              "question_number": `${i + 1}`,
                              "correct_answer": "1",
                              "question_media_type": "",
                              "question_media": "",
                              "answer_media_type": "",
                              "option_1": "",
                              "option_2": "",
                              "option_3": "",
                              "option_4": ""
                            }
                          }
                          setQuestionsData(question)
                        }
                        setNumberofQuestionSet(false)
                      }}
                    >
                      Set Question
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            <div style={{ overflowX: "scroll" }}>
              <div
                className="row"
                onClick={() => {

                }}
              >
                {/* <div style={{ width: "5%", display: "inline", textAlign: "center" }}>QN</div> */}
                <div style={{ width: "10%", textAlign: "center" }}>QN No</div>
                <div style={{ width: "22%" }}>Correct Answer</div>
                <div style={{ width: "18%" }}>Question Audio</div>
                <div style={{ width: "50%" }}>Answer Audio</div>
              </div>
              {Object.values(QuestionsData).map((item, index) => (
                <div key={index} style={{ cursor: "pointer", backgroundColor: index % 2 == 0 ? "#fff" : "#eee", padding: "5px 0" }}
                  className="row"
                >
                  <QuestionRow
                    index={index} QuestionsData={QuestionsData} setQuestionsData={setQuestionsData}
                  />


                </div>
              ))}
            </div>
          }
        </div>
      </div>
      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div >
  )

}

const QuestionRow = ({ index, QuestionsData, setQuestionsData }) => {
  const item = Object.values(QuestionsData)[index]
  const [QuestionAudio, setQuestionAudio] = React.useState(Object.values(QuestionsData)[index].question_media)
  const [AnswerAudio1, setAnswerAudio1] = React.useState(Object.values(QuestionsData)[index].option_1)
  const [AnswerAudio2, setAnswerAudio2] = React.useState(Object.values(QuestionsData)[index].option_2)
  const [AnswerAudio3, setAnswerAudio3] = React.useState(Object.values(QuestionsData)[index].option_3)
  const [AnswerAudio4, setAnswerAudio4] = React.useState(Object.values(QuestionsData)[index].option_4)

  return (
    <>
      <div style={{ width: "10%", textAlign: "center" }}>{item.question_number}</div>
      <div style={{ width: "22%" }}>
        <OptionChange index={index} option={1} QuestionsData={QuestionsData} setQuestionsData={setQuestionsData} />
        <OptionChange index={index} option={2} QuestionsData={QuestionsData} setQuestionsData={setQuestionsData} />
        <OptionChange index={index} option={3} QuestionsData={QuestionsData} setQuestionsData={setQuestionsData} />
        <OptionChange index={index} option={4} QuestionsData={QuestionsData} setQuestionsData={setQuestionsData} />
      </div>
      <div style={{ width: "18%" }}>
        {Object.values(QuestionsData)[index].question_media_type == "audio" ?
          <>
            <i className="fa fa-check-square-o"
              onClick={() => {
                var question = QuestionsData;
                var questionkey = Object.keys(QuestionsData)[index]
                var questionvalue = Object.values(QuestionsData)[index]
                console.log(questionkey, questionvalue)
                question = {
                  ...question,
                  [questionkey]: {
                    ...questionvalue,
                    "question_media_type": "",
                    "question_media": "",
                  }
                }
                setQuestionsData(question)
                setQuestionAudio("")
              }}
            />
            <AudioTrue index={index}
              QuestionsData={QuestionsData} setQuestionsData={setQuestionsData}
              url={"question_media"} setQuestionAudio={setQuestionAudio} QuestionAudio={QuestionAudio}
            />
          </>
          :
          <>
            <AudioFalse index={index}
              QuestionsData={QuestionsData} setQuestionsData={setQuestionsData}
              type={"question_media_type"} url={"question_media"}
            />
          </>
        }
      </div>
      <div style={{ width: "50%" }}>
        {Object.values(QuestionsData)[index].answer_media_type == "audio" ?
          <>
            <i className="fa fa-check-square-o"
              onClick={() => {
                var question = QuestionsData;
                var questionkey = Object.keys(QuestionsData)[index]
                var questionvalue = Object.values(QuestionsData)[index]
                console.log(questionkey, questionvalue)
                question = {
                  ...question,
                  [questionkey]: {
                    ...questionvalue,
                    "answer_media_type": "",
                    "option_1": "",
                    "option_2": "",
                    "option_3": "",
                    "option_4": ""
                  }
                }
                setQuestionsData(question)
              }}
            />
            <br />
            <div className="row">
              <div className="col-sm-3 col-6">
                1 <AudioTrue index={index}
                  QuestionsData={QuestionsData} setQuestionsData={setQuestionsData}
                  url={"option_1"} setQuestionAudio={setAnswerAudio1} QuestionAudio={AnswerAudio1}
                />
              </div>
              <div className="col-sm-3 col-6">
                2 <AudioTrue index={index}
                  QuestionsData={QuestionsData} setQuestionsData={setQuestionsData}
                  url={"option_2"} setQuestionAudio={setAnswerAudio2} QuestionAudio={AnswerAudio2}
                />
              </div>
              <div className="col-sm-3 col-6">
                3 <AudioTrue index={index}
                  QuestionsData={QuestionsData} setQuestionsData={setQuestionsData}
                  url={"option_3"} setQuestionAudio={setAnswerAudio3} QuestionAudio={AnswerAudio3}
                />
              </div>
              <div className="col-sm-3 col-6">
                4 <AudioTrue index={index}
                  QuestionsData={QuestionsData} setQuestionsData={setQuestionsData}
                  url={"option_4"} setQuestionAudio={setAnswerAudio4} QuestionAudio={AnswerAudio4}
                />
              </div>
            </div>

          </>
          :
          <>
            <AudioFalseAnswer index={index}
              QuestionsData={QuestionsData} setQuestionsData={setQuestionsData}
              type={"answer_media_type"}
            />
          </>
        }
        {/* {JSON.stringify(Object.values(QuestionsData)[index])} */}
      </div>
    </>
  )

}


const AudioFalseAnswer = ({ QuestionsData, index, setQuestionsData, type }) => {
  return (
    <div onClick={() => {
      var question = QuestionsData;
      var questionkey = Object.keys(QuestionsData)[index]
      var questionvalue = Object.values(QuestionsData)[index]
      console.log(questionkey, questionvalue)
      question = {
        ...question,
        [questionkey]: {
          ...questionvalue,
          [type]: `audio`,
        }
      }
      setQuestionsData(question)
    }}>
      <i className="fa fa-square-o" />
    </div>
  )
}


const AudioFalse = ({ QuestionsData, index, setQuestionsData, type }) => {
  return (
    <div onClick={() => {
      var question = QuestionsData;
      var questionkey = Object.keys(QuestionsData)[index]
      var questionvalue = Object.values(QuestionsData)[index]
      console.log(questionkey, questionvalue)
      question = {
        ...question,
        [questionkey]: {
          ...questionvalue,
          [type]: `audio`,
        }
      }
      setQuestionsData(question)
    }}>
      <i className="fa fa-square-o" />
    </div>
  )
}

const AudioTrue = ({ QuestionsData, index, setQuestionsData, url, QuestionAudio, setQuestionAudio }) => {
  return (
    <>

      <AudioUpload change_type={url}
        QuestionsData={QuestionsData}
        index={index}
        setQuestionsData={setQuestionsData}
        ImageUrl={QuestionAudio}
        setImageUrl={setQuestionAudio}
      />
      {QuestionAudio !== "" &&
        <a style={{ color: "#000" }} href={QuestionAudio} target="_blank">{Object.values(QuestionsData)[index].question_media_type}</a>
      }

    </>
  )
}

const OptionChange = ({ option, index, QuestionsData, setQuestionsData }) => {
  return (
    <span onClick={() => {
      var question = QuestionsData;
      var questionkey = Object.keys(QuestionsData)[index]
      var questionvalue = Object.values(QuestionsData)[index]
      console.log(questionkey, questionvalue)
      question = {
        ...question,
        [questionkey]: {
          ...questionvalue,
          "correct_answer": `option ${option}`,
        }
      }
      setQuestionsData(question)
    }}>
      {Object.values(QuestionsData)[index].correct_answer == `option ${option}` ?
        <i className="fa fa-check-square-o" />
        :
        <i className="fa fa-square-o" />
      }
      &nbsp;{option}&nbsp;
    </span>
  )
}