import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsPOSTApi, apiUrl } from "../../Constant/api";
import { slugs } from "../../Constant/slugs";
import axios from "axios";
// import macaddress from "macaddress";
import { v4 as uuidv4 } from 'uuid';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

// import uuid from "uuid";

export default function VerifyAllExam() {
  const location = useLocation()
  const history = useHistory()

  const [reload, setReload] = React.useState(false)
  const [responseReturn, setResponseReturn] = React.useState(true)
  const [fpHash, setFpHash] = React.useState('');

  const TESTSuccess = (params) => {
    setReload(true)

    axios({
      method: "POST",
      url: `${apiUrl}wp-json/wp/v2/${slugs.db_slug_daily_exam}?_fields=id,slug,title,meta`,
      headers: {
        "Authorization": `Basic YW5pc2g6YQ==`,
        // "Authorization": `Bearer ${token}`,
      },
      data: {
        title: ""
      }
    })
      .then((res) => {

        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }
  useEffect(() => {
    if (location.pathname.slice(17) == 12)
      TESTSuccess()
    // macaddress.one(function (err, mac) {
    //   console.log("Mac address for this host: %s", mac);
    // });
    // var uuid = new DeviceUUID().get();
    // console.log(uuid);
    const setFp = async () => {
      const fp = await FingerprintJS.load();

      const { visitorId } = await fp.get();

      setFpHash(visitorId);
    };

    setFp();
  }, [])

  return (
    <div>
        <h3>Hash: {fpHash}</h3>
      {JSON.stringify(uuidv4())}
      {JSON.stringify(location.pathname.slice(17))}
      {responseReturn &&
        "Contact Admin for further process"

      }
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}